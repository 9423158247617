import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import { AppBar, Typography, Toolbar, Button, IconButton, Tooltip, Hidden, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useIntl } from 'react-intl';
import { useStyles } from './Header.styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { General } from "../../Modules/LawPortal/Features/Settings/General";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { getAuthProvider } from "../../Auth/AuthProvider";
import { UserContext } from "../../Context/UserContext/UserContext";
import { shutdown } from '@intercom/messenger-js-sdk';
export interface IHeaderSubMenuItem {
  title: React.ReactNode;
  to: string;
}

interface IHeaderProps {
  title: React.ReactNode;
  subMenu?: IHeaderSubMenuItem[];
  sidebarOpen?: boolean;
  handleSidebarToggle?: (open: boolean) => void;
}

export const Header: FC<IHeaderProps> = ({ title, subMenu, sidebarOpen, handleSidebarToggle }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const activeSubMenu = subMenu
    ?.filter((menu) => window.location.pathname.indexOf(menu.to) >= 0)
    .sort((a, b) => b.to.length - a.to.length)
    .find((_) => true);
  const getClassName = (menu: IHeaderSubMenuItem) => {
    return menu === activeSubMenu ? classes.active : classes.inactive;
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { firstName, lastName } = useContext(UserContext);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    const authProvider = getAuthProvider();
    localStorage.clear();
    shutdown()
    authProvider.logout();
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.root, { [classes.shift]: sidebarOpen })}
        classes={{ positionFixed: clsx({ [classes.positionFixed]: !sidebarOpen }) }}
      >
        <Toolbar>
          <Hidden lgUp>
            <Tooltip title={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}>
              <IconButton
                color="inherit"
                aria-label={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}
                edge="start"
                onClick={() => handleSidebarToggle && handleSidebarToggle(true)}
                className={clsx(classes.menuButton, { [classes.hide]: sidebarOpen })}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
          <div style={{ width: '97%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2" component="h1">
              {title}
            </Typography>
            <Typography variant="h2" component="h1">
              <Avatar className={classes.avatarColor} onClick={handleClick} >{firstName?.charAt(0)}{lastName?.charAt(0)}</Avatar>
            </Typography>
          </div>
        </Toolbar>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          style={{marginTop:'40px'}}
          >
          <div>
            <General setAnchorEl={() => setAnchorEl(null)} />
          </div>
          <MenuItem onClick={handleLogout} style={{display:'flex',gap:'10px'}} ><MeetingRoomIcon /> {formatMessage({ id: 'component.sidebar.logout', defaultMessage: 'logout' })}</MenuItem>
        </Menu>
        {subMenu && (
          <Toolbar className={classes.subMenu} component="nav" variant="dense" disableGutters>
            <div className={classes.subMenuWrapper}>
              {subMenu.map((menu, index) => (
                <Button
                  key={`menu-item-${index}`}
                  className={`${classes.subMenuButton} ${getClassName(menu)}`}
                  component={Link}
                  to={menu.to}
                  variant="text"
                >
                  {menu.title}
                </Button>
              ))}
            </div>
          </Toolbar>
        )}
      </AppBar>
      {/* Needed for page content to be placed below header */}
      <div className={classes.toolbar} />
      {subMenu && <div className={classes.subMenuToolbar} />}
    </>
  );
};
