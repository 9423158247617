import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MTable, RepealableName } from "../../../../../../Components";
import { useIntl } from "react-intl";
import { Button, LinearProgress, Theme, Tooltip, createStyles, withStyles } from "@material-ui/core";
import { useAptorApi } from "../../../../../../Api";
// import async from "react-select/async";
import { useComplianceState } from "../List/ComplianceState";
// import { features } from "process";
import { AccessLevel, LawPortalFeature, Module, UserContext, planType } from "../../../../../../Context/UserContext/UserContext";
import { DoneOutline, Edit } from "@material-ui/icons";
import { Query } from "material-table";
import { IGridQueryRequest, OrderDirection } from "../../../../../../Api/AptorApi";
import { ModuleContext } from '../../../../../../Context/ModuleContext/ModuleContext';
import { useSnackbar } from 'notistack';

interface BorderLinearProgressProps {
  name?: string; // Define the type of the name prop
  variant: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
}
interface IProps {
  planData: any
  filters: any
  search: any
  activeTab?: string
}
const PlanningViewLK = ({ planData, filters, search, activeTab }: IProps) => {
  const {
    // enableCompliance,
    // complianceEnabled,
    // disableCompliance,
    startComplianceSession,
    // hasCompliance,
    // isComplianceUserFor,
    toggleCompliance,
    // setLawsSelectedForCompliance,
    setPlan,
    setPLanEdit
  } = useComplianceState();
  const tableRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  let { formatMessage } = useIntl();
  const {
    api,
    //  abortController 
  } = useAptorApi();
  const [laws, setLaws] = useState([])
  const [requirement, setRequirement] = useState([])
  const { firstName, lastName, hasAccessToModule, hasAccessToFeature } = useContext(UserContext);
  const currentuserName = `${firstName} ${lastName}`
  const { setHeader } = useContext(ModuleContext);

  useEffect(() => {
    var menu = []
    if (hasAccessToModule(Module.Administration) || hasAccessToModule(Module.Organization)) {
      menu = [
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
          to: `/law-portal/legal-compliance-control/planning`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    } else {
      menu = [
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), menu);
  }, [setHeader, formatMessage]);
  // useEffect(() => {
  //     getDirectPLanlaws()
  // }, [])

  // const getDirectPLanlaws = async () => {
  //     const response = await api.getdirectPlanLaws(planData.id)
  //     setLaws(response.laws)
  //     setRequirement(response.requirements)
  // }
  const handleLawDataFetch = useCallback(
    async (query: Query<any>) => {
      return await api.searchLawPortalPlanLaws<any>(planData.id, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest).then(res => {
        if (hasAccessToFeature(LawPortalFeature.Statistics, AccessLevel.Read)) {
          return res
        }else{
          let data= res.data.filter((val: any, index: any) => {
            const responsible = val?.responsible?.split(',')
            responsible?.forEach((item: any, index: any, array: any) => {
              array[index] = item.toLowerCase();
            });
            if (responsible?.includes(currentuserName.toLowerCase())) {
              return val
            }
          })
          res.data = data
          return res
        }
      });
    },
    [api, filters],
  );
  const handleReqDataFetch = useCallback(
    async (query: Query<any>) => {
      return await api.searchLawPortalPlanRequirementLaws<any>(planData.id, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest).then(res => {
        if (hasAccessToFeature(LawPortalFeature.Statistics, AccessLevel.Read)) {
          return res
        }else{
          let data= res.data.filter((val: any, index: any) => {
            const responsible = val?.responsible?.split(',')
            responsible?.forEach((item: any, index: any, array: any) => {
              array[index] = item.toLowerCase();
            });
            if (responsible?.includes(currentuserName.toLowerCase())) {
              return val
            }
          })
          res.data = data
          return res
        }
      });
    },
    [api, filters],
  );
  // const BorderLinearProgress = withStyles((theme: Theme) =>
  //     createStyles({
  //         root: {
  //             height: 25,
  //             borderRadius: 12,
  //             boderWidth: '3px',
  //             borderColor: (props: BorderLinearProgressProps) =>
  //                 props.value >=90 ? 'green' : props.value <=30 ? "red" : 'yellow',
  //             borderStyle: 'solid',
  //         },
  //         colorPrimary: {
  //             backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  //         },
  //         bar: {
  //             borderRadius: 5,
  //             // Use the name prop to set the background color of the bar
  //             backgroundColor: (props: BorderLinearProgressProps) =>
  //                 props.value >=90 ? 'green' : props.value === 0 ? "":props?.value>0&&props?.value<=30?"red" : 'yellow',
  //         },
  //     }),
  // )(({ ...props }: BorderLinearProgressProps) => <LinearProgress {...props} />);
  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 25,
        borderRadius: 12,
        boderWidth: '3px',
        borderColor: (props: BorderLinearProgressProps) =>
          props.value === 100 ? 'green' : props.value === 0 ? "red" : 'yellow',
        borderStyle: 'solid',
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        // Use the name prop to set the background color of the bar
        backgroundColor: (props: BorderLinearProgressProps) =>
          props.value === 100 ? 'green' : props.value === 0 ? "" : 'yellow',
      },
    }),
  )(({ ...props }: BorderLinearProgressProps) => <LinearProgress {...props} />);

  const [startCompliance, setStartCompliance] = useState(false)

  const handleComplianceSession = async (event: any, lawData: any) => {
    setStartCompliance(true)
    event.stopPropagation();
    setPlan(planData)
    await toggleCompliance(lawData)
    setStartCompliance(false)
  }
  const handleEditSession = async (lawData: any) => {
    setStartCompliance(true)
    setPlan(planData)
    setPLanEdit(true)
    await toggleCompliance(lawData)
    setStartCompliance(false)
  }
  useEffect(() => {
    startComplianceSession()
  }, [startCompliance])

  const handlemsg = () => {
    enqueueSnackbar(formatMessage({ id: 'component.law-error-description' }, { companyunitname: planData?.customerCompanyUnitId?.name }), { variant: 'error' })
  }
  const [column, setColumn] = useState([
    { title: formatMessage({ id: 'law-portal.manage.law-list.table.number' }), field: 'number' },
    {
      title: formatMessage({ id: 'law-portal.manage.law-list.table.law' }),
      field: 'name',
      render: (row: any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
    },
    {
      title: formatMessage({ id: 'law-portal.complince.direct.table.header.status' }),
      field: 'status',
      render: (row) => <BorderLinearProgress variant="determinate" value={row?.status || 0} />,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance.tab.carryout.table.user' }),
      field: 'responsible',
      render: (row: any) => {
        if (row.responsible) {
          const responsiblearr = row?.responsible?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {responsiblearr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
    },
    {
      title: '',
      field: '',
      render: (row) => {
        const responsible = row?.responsible?.split(',')
        responsible?.forEach((item: any, index: any, array: any) => {
          array[index] = item.toLowerCase();
        });
        return (
          <>
            {activeTab != 'complete' && responsible?.includes(currentuserName.toLowerCase()) ? row.isStarted ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '7px' }}>
                {/* <Tooltip title={formatMessage({ id: "law-portal.our-law.compliance.complete" })}>
                                    <DoneOutline style={{ cursor: 'pointer' }} />
                                </Tooltip> */}
                <Tooltip title={formatMessage({ id: "law-portal.our-law.compliance.edit" })}>
                  <Edit style={{ cursor: 'pointer' }} onClick={() => row?.hasAccessToExecute == 0 ? handlemsg() : handleEditSession(row)} />
                </Tooltip>
              </div>
              : <Button
                variant="contained"
                onClick={(event: any) => row?.hasAccessToExecute == 0 ? handlemsg() : handleComplianceSession(event, row)}
              >
                {planData?.type === 1 ? formatMessage({ id: "law-portal.our-laws.compliance.tab.carryout.kek.create.button" }) : formatMessage({ id: "law-portal.our-laws.compliance.tab.carryout.create.button" })}
              </Button> : ""}
          </>
        );
      },
    },
  ])

  useEffect(() => {
    if (planData.type === planType.requirement) {
      const columnData = column?.filter((val) => val.field !== "number")
      setColumn(columnData)
    }
  }, [])
  return (
    <>
      <div style={{ paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px' }}>
        <MTable
          tableRef={tableRef}
          columns={column}
          hideToolbar
          data={planData.type === planType.requirement ? handleReqDataFetch : handleLawDataFetch}
          actionLabel={''}
          components={{}}
        />
      </div>
    </>
  )
}

export default PlanningViewLK